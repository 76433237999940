/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Block from '../../components/block/Block';
import Article from '../../components/article/Article';
import Container from '../../components/block/Container';

const audioPlayers = {};

const registerAudioPlayer = ({ id, playerRef }) => {
  audioPlayers[id] = playerRef;
};

const stopAudioPlayers = ({ id }) => {
  Object.keys(audioPlayers).forEach((key) => {
    if (key !== id) {
      audioPlayers[key].stop();
    }
  });
};

const MusicIndexPage = ({ data, intl: { locale, formatMessage } }) => {
  const posts = data[locale].edges;
  return (
    <Layout>
      <SEO title="Cyril Planchon" />
      <Container>
        <Block
          title={formatMessage({ id: 'menu@music' })}
        >
          {
            posts.map(({ node }) => {
              return (
                <Article
                  {...node}
                  body={node.description}
                  registerAudioPlayer={registerAudioPlayer}
                  stopAudioPlayers={stopAudioPlayers}
                />
              )
            })
          }
        </Block>
      </Container>
    </Layout>
  );
};

MusicIndexPage.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    fr: PropTypes.shape({
      edges: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default injectIntl(MusicIndexPage);

export const query = graphql`
  query MusicIndexPageQuery {
    en: allContentfulAlbum(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          id
          title
          description {
            json
          }
          playlist {
            id
            title
            track {
              file {
                fileName
                url
              }
            }
            duration
          }
          image {
            image {
              id
              file {
                url
                fileName
              }
            }
            portrait
          }
        }
      }
    }
    fr: allContentfulAlbum(filter: {node_locale: {eq: "fr"}}) {
      edges {
        node {
          id
          title
          description {
            json
          }
          playlist {
            id
            title
            track {
              file {
                url
              }
            }
            duration
          }
          image {
            image {
              id
              file {
                url
                fileName
              }
            }
            portrait
          }
        }
      }
    }
  }
`;
